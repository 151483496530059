var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.layout,{tag:"component"},[_c('div',{staticClass:"d-flex"},[_c('prompt',{attrs:{"active":_vm.exportModal,"title":'Export Orders'},on:{"close":function($event){_vm.exportModal = false}}},[_c('div',{attrs:{"slot":"content"},slot:"content"},[(_vm.exportModal)?_c('export',{attrs:{"conditionData":_vm.conditionData}}):_vm._e()],1)]),_c('OverviewSidebar',{ref:"OverviewSidebar",staticClass:"justify-content-between fadeInUp",attrs:{"overview":"order"},on:{"filter":_vm.onFilter}}),_c('div',{staticClass:"p-5 flex-fill fadeInUp has-sidebar",attrs:{"role":"main"}},[(_vm.renderConditionBuilder)?_c('ConditionBuilder',{attrs:{"conditionData":_vm.conditionData,"model":'order'},on:{"apply-filters":_vm.setConditionData,"segment-saved":_vm.updateFilters}}):_vm._e(),_c('div',{staticClass:"card border-0 shadow-sm mb-4"},[_c('div',{staticClass:"card-header"},[_c('h5',[_vm._v(" Orders "),(_vm.orders.meta.total !== 0)?_c('span',{staticClass:"text-muted ml-4"},[_vm._v(" "+_vm._s(_vm.orders.meta.total)+" orders ")]):_vm._e(),_c('button',{staticClass:"btn btn-secondary-soft page-intro m-1",on:{"click":_vm.runPageIntro}},[_vm._v(" Start page intro ")]),_c('button',{staticClass:"btn btn-primary float-right m-1",on:{"click":function($event){_vm.exportModal = true}}},[_vm._v(" Export ")])])]),_c('api-loader',{attrs:{"isLoading":_vm.loading}},[(Object.keys(_vm.orders.data).length !== 0)?_c('div',{staticClass:"card-body",attrs:{"data-title":"Sorting is available on most columns. Sort all orders filtered across pages.","data-intro":"","data-step":"5","data-position":"left"}},[_c('data-table',{attrs:{"headers":_vm.headers(),"sort-url":"/order","data":_vm.orders.data},on:{"sort":_vm.setSorting},scopedSlots:_vm._u([{key:"order_number",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.order_number)+" ")]}},{key:"total",fn:function(ref){
var item = ref.item;
return [_c('money-format',{attrs:{"value":item.total / 100,"locale":'en',"currency-code":item.currency}})]}},{key:"status",fn:function(ref){
var item = ref.item;
return [_c('status-badge',{attrs:{"variant":item.status}},[_vm._v(" "+_vm._s(item.status)+" ")])]}},{key:"date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatLocaleDateString")(item.created_at))+" ")]}},{key:"actions",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"btn btn-sm btn-outline-secondary",attrs:{"to":{ name: 'order.single', params: { id: item.id } }}},[_vm._v(" View order ")])]}}],null,false,3373093154)}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-12"},[_c('pagination',{attrs:{"data":_vm.orders,"limit":3,"show-disabled":true,"align":"right"},on:{"pagination-change-page":_vm.setPage}},[_c('span',{staticClass:"text-capitalize",attrs:{"slot":"prev-nav"},slot:"prev-nav"},[_c('i',{staticClass:"fas fa-chevron-left mr-2"}),_vm._v(" Prev ")]),_c('span',{staticClass:"text-capitalize",attrs:{"slot":"next-nav"},slot:"next-nav"},[_vm._v(" Next "),_c('i',{staticClass:"fas fa-chevron-right ml-2"})])])],1)])],1):_c('div',{staticClass:"card-body"},[_c('p',{staticClass:"text-muted"},[_vm._v(" This order list is empty: You're seeing this message either because: 1. There are no relevant orders to show for the filter 2. The plugin/api is not correctly connected 3. Automation.app is (unlikely) down. To resolve this, try new filtering options or contact "),_c('a',{attrs:{"href":"mailto:nerds@automation.app"}},[_vm._v("nerds@automation.app")]),_vm._v(". ")]),_c('div',{staticClass:"p-4 rounded border-zip text-center"},[_c('p',{staticClass:"mb-0"},[_vm._v(" No orders to display! ")])])])])],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }