<template>
  <component :is="layout">
    <div class="d-flex">
      <prompt
        :active="exportModal"
        :title="'Export Orders'"
        @close="exportModal = false"
      >
        <div slot="content">
          <export v-if="exportModal" :conditionData="conditionData" />
        </div>
      </prompt>

      <OverviewSidebar
        ref="OverviewSidebar"
        class="justify-content-between fadeInUp"
        overview="order"
        @filter="onFilter"
      />
      <div class="p-5 flex-fill fadeInUp has-sidebar" role="main">
        <ConditionBuilder
          v-if="renderConditionBuilder"
          @apply-filters="setConditionData"
          @segment-saved="updateFilters"
          :conditionData="conditionData"
          :model="'order'"
        />
        <div class="card border-0 shadow-sm mb-4">
          <div class="card-header">
            <h5>
              Orders
              <span v-if="orders.meta.total !== 0" class="text-muted ml-4">
                {{ orders.meta.total }} orders
              </span>

              <button
                class="btn btn-secondary-soft page-intro m-1"
                @click="runPageIntro"
              >
                Start page intro
              </button>
              <button
                class="btn btn-primary float-right m-1"
                @click="exportModal = true"
              >
                Export
              </button>
            </h5>
          </div>
          <api-loader :isLoading="loading">
            <div
              v-if="Object.keys(orders.data).length !== 0"
              class="card-body"
              data-title="Sorting is available on most columns. Sort all orders filtered across pages."
              data-intro=""
              data-step="5"
              data-position="left"
            >
              <data-table
                :headers="headers()"
                sort-url="/order"
                :data="orders.data"
                @sort="setSorting"
              >
                <template #order_number="{ item }">
                  {{ item.order_number }}
                </template>
                <template #total="{ item }">
                  <money-format
                    :value="item.total / 100"
                    :locale="'en'"
                    :currency-code="item.currency"
                  />
                </template>
                <template #status="{ item }">
                  <status-badge :variant="item.status">
                    {{ item.status }}
                  </status-badge>
                </template>
                <template #date="{ item }">
                  {{ item.created_at | formatLocaleDateString }}
                </template>
                <template #actions="{ item }">
                  <router-link
                    :to="{ name: 'order.single', params: { id: item.id } }"
                    class="btn btn-sm btn-outline-secondary"
                  >
                    View order
                  </router-link>
                </template>
              </data-table>
              <div class="row">
                <div class="col-12 col-md-12">
                  <pagination
                    :data="orders"
                    :limit="3"
                    :show-disabled="true"
                    align="right"
                    @pagination-change-page="setPage"
                  >
                    <span slot="prev-nav" class="text-capitalize">
                      <i class="fas fa-chevron-left mr-2" /> Prev
                    </span>
                    <span slot="next-nav" class="text-capitalize">
                      Next <i class="fas fa-chevron-right ml-2" />
                    </span>
                  </pagination>
                </div>
              </div>
            </div>
            <div v-else class="card-body">
              <p class="text-muted">
                This order list is empty: You're seeing this message either
                because: 1. There are no relevant orders to show for the filter
                2. The plugin/api is not correctly connected 3. Automation.app
                is (unlikely) down. To resolve this, try new filtering options
                or contact
                <a href="mailto:nerds@automation.app">nerds@automation.app</a>.
              </p>
              <div class="p-4 rounded border-zip text-center">
                <p class="mb-0">
                  No orders to display!
                </p>
              </div>
            </div>
          </api-loader>
        </div>
      </div>
    </div>
  </component>
</template>

<script>
import MoneyFormat from "vue-money-format";
import "intro.js/minified/introjs.min.css";
import introJs from "intro.js/intro.js";
import jsonAPI from "@/api";
import Default from "@/layouts/default.vue";
import DataTable from "@/components/DataTable/DataTable.vue";
import StatusBadge from "@/components/UI/StatusBadge.vue";
import { updateApi, buildPaginationString } from "@/helpers/apiConnection";
import ConditionBuilder from "@/components/Automation/ConditionBuilder.vue";
import OverviewSidebar from "@/components/Order/OverviewSidebar.vue";
import ApiLoader from "@/components/UI/ApiLoader.vue";
import Prompt from "@/components/UI/Prompt.vue";
import Export from "@/components/Order/Export.vue";

export default {
  name: "OrderList",
  components: {
    DataTable,
    Default,
    StatusBadge,
    MoneyFormat,
    ConditionBuilder,
    OverviewSidebar,
    ApiLoader,
    Prompt,
    Export,
  },
  data() {
    return {
      layout: "Default",
      loading: false,
      orders: {
        data: [],
        meta: {
          total: 0,
        },
        links: {},
      },
      activeSegment: "",
      selectedDateRange: "",
      activeTag: "",
      page: 1,
      renderConditionBuilder: true,
      conditionData: {},
      exportModal: false,
    };
  },
  async beforeRouteEnter(to, from, next) {
    const response = await jsonAPI.get("/configuration/view");
    const { data } = response.data;
    const defaultView = data.filter((item) => item.data.default === true);
    if (defaultView.length > 0) {
      const view = defaultView.pop();
      next((vm) => {
        vm.$router.push({
          name: "order.view",
          params: {
            id: view.id,
          },
          query: vm.$route.query,
        });
      });
    }
  },
  async mounted() {
    this.loading = true;

    await this.getOrders(1);
    this.loading = false;
  },
  methods: {
    runPageIntro() {
      introJs().start();
    },
    headers() {
      return [
        {
          title: "#",
          key: "order_number",
          sortable: true,
          sortkey: "order_number",
        },
        { title: "Total", key: "total", sortable: true, sortkey: "total" },
        { title: "Status", key: "status", sortable: true, sortkey: "status" },
        {
          title: "Created on",
          key: "date",
          sortable: true,
          sortkey: "created_at",
        },
        { title: "Actions", key: "actions" },
      ];
    },
    setPage(page) {
      this.page = page;
      this.getOrders(page, this.activeSegment);
    },
    setSorting(sorting) {
      const sort = sorting.sortKey;
      const direction = sorting.direction;
      this.$router
        .replace({
          query: {
            sort: sort,
            dir: direction,
          },
        })
        .catch((error) => {
          console.log(error);
        });
      this.getOrders(this.page);
    },
    onFilter(condition) {
      this.conditionData = {
        conditions: condition.conditions,
        conditionGroup: condition.conditions_group,
      };
      // if no conditions are set, reset the conditionbuilder component
      if (Array.isArray(condition)) {
        if (condition.length === 0) {
          this.renderConditionBuilder = false;
          this.$nextTick(() => {
            this.renderConditionBuilder = true;
          });
        }
      }
      this.getOrders(this.page);
    },
    updateFilters() {
      this.$refs.OverviewSidebar.reload();
    },
    getOrders: async function(page = 1) {
      const sortKey = this.$route.query.sort ?? "created_at";
      const sortDirection = this.$route.query.dir ?? "desc";
      const query = buildPaginationString(sortKey, sortDirection, page);
      const url = `/order?${query}`;
      this.orders = await updateApi(url, this.conditionData);
    },
    resetFilters() {
      this.selectedDateRange = "";
      this.activeSegment = "";
      this.activeTag = "";
      this.getOrders(this.page);
    },
    setConditionData(conditionData) {
      this.conditionData = conditionData;
      this.getOrders();
    },
  },
};
</script>

<style lang="scss" scoped></style>
